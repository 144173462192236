import React from "react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { DiMongodb, DiPython, DiGit, DiJava } from "react-icons/di";
import { SiRedis, SiPostgresql, SiKubernetes, SiSpring } from "react-icons/si";
import { CgCircleci, CgDatabase, CgShapeHexagon } from "react-icons/cg";

function Techstack() {
    const renderTooltip = (props, name) => (
        <Tooltip id={`tooltip-${name.toLowerCase()}`} {...props}>
            {name}
        </Tooltip>
    );

    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "CircleCI")}
                >
                    <span><CgCircleci /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Spring Boot")}
                >
                    <span><SiSpring /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Kubernetes")}
                >
                    <span><SiKubernetes /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Oracle DB")}
                >
                    <span><CgDatabase /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Hexagonal Architecture")}
                >
                    <span><CgShapeHexagon /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "MongoDB")}
                >
                    <span><DiMongodb /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Git")}
                >
                    <span><DiGit /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Redis")}
                >
                    <span><SiRedis /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "PostgreSQL")}
                >
                    <span><SiPostgresql /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Python")}
                >
                    <span><DiPython /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Java")}
                >
                    <span><DiJava /></span>
                </OverlayTrigger>
            </Col>
        </Row>
    );
}

export default Techstack;