import React, { useEffect } from "react";

function TextBounceSVG() {
    useEffect(() => {
        // Array of texts to animate
        const texts = [
            { id: 'macos', dx: 0.5, dy: 0.5 },
            { id: 'vscode', dx: 0.7, dy: -0.5 },
            { id: 'postman', dx: 0.3, dy: 0.4 },
            { id: 'intellij', dx: 0.4, dy: -0.3 },
            { id: 'spring', dx: -0.4, dy: 0.4 },
            { id: 'mongodb', dx: 0.4, dy: 0.6 },
            { id: 'kubernetes', dx: 0.5, dy: -0.5 },
            { id: 'ci_cd', dx: -0.4, dy: 0.5 },
            { id: 'gcp', dx: 0.3, dy: -0.5 },
            { id: 'coolify', dx: -0.3, dy: 0.4 }
        ];

        const svgWidth = 400;
        const svgHeight = 400;

        function animateText() {
            texts.forEach((textObj) => {
                const textElement = document.getElementById(textObj.id);
                if (!textElement) return;

                let x = parseFloat(textElement.getAttribute('x'));
                let y = parseFloat(textElement.getAttribute('y'));

                // Update position
                x += textObj.dx;
                y += textObj.dy;

                // Detect collision with walls and reverse direction if necessary
                if (x <= 0 || x >= svgWidth - 50) textObj.dx *= -1; // Text width approx
                if (y <= 20 || y >= svgHeight - 20) textObj.dy *= -1; // Text height approx

                // Apply new position
                textElement.setAttribute('x', x);
                textElement.setAttribute('y', y);
            });

            // Continue animation
            requestAnimationFrame(animateText);
        }

        // Start animation
        animateText();
    }, []);

    return (
        <div id="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="100%" height="100%">
                <text id="macos" x="50" y="50" font-size="24" fill="#149414" font-family="Arial">MacOS</text>
                <text id="vscode" x="150" y="100" font-size="24" fill="#149414" font-family="Arial">Visual Studio Code</text>
                <text id="postman" x="250" y="150" font-size="24" fill="#149414" font-family="Arial">Postman</text>
                <text id="intellij" x="200" y="200" font-size="24" fill="#149414" font-family="Arial">IntelliJ IDEA</text>
                <text id="spring" x="100" y="250" font-size="24" fill="#149414" font-family="Arial">Spring Boot</text>
                <text id="mongodb" x="200" y="100" font-size="24" fill="#149414" font-family="Arial">MongoDB</text>
                <text id="kubernetes" x="50" y="100" font-size="24" fill="#149414" font-family="Arial">Kubernetes</text>
                <text id="ci_cd" x="300" y="50" font-size="24" fill="#149414" font-family="Arial">CI/CD</text>
                <text id="gcp" x="10" y="100" font-size="24" fill="#149414" font-family="Arial">GCP</text>
                <text id="coolify" x="250" y="200" font-size="24" fill="#149414" font-family="Arial">Coolify</text>
            </svg>
        </div>
    );
}

export default TextBounceSVG;