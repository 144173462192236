import React from "react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaApple, FaJira } from "react-icons/fa"; // MacOS e Jira
import { SiVisualstudiocode, SiPostman, SiConfluence, SiJetbrains } from "react-icons/si";
import { CgDatabase } from "react-icons/cg";
import { DiAtom } from "react-icons/di"; // Placeholder for Artifactory

function Techstack() {
    const renderTooltip = (props, name) => (
        <Tooltip id={`tooltip-${name.toLowerCase()}`} {...props}>
            {name}
        </Tooltip>
    );

    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "MacOS")}>
                    <span><FaApple /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "Visual Studio Code")}>
                    <span><SiVisualstudiocode /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "Postman")}>
                    <span><SiPostman /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "IntelliJ IDEA")}>
                    <span><SiJetbrains /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "DataGrip")}>
                    <span><SiJetbrains /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "DBeaver")}>
                    <span><CgDatabase /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "Jira")}>
                    <span><FaJira /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "Confluence")}>
                    <span><SiConfluence /></span>
                </OverlayTrigger>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, "Artifactory")}>
                    <span><DiAtom /></span>
                </OverlayTrigger>
            </Col>
        </Row>
    );
}

export default Techstack;